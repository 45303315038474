$( function() {

  $('[data-toggle="dropdown"]').dropdown()

  $( document ).on('click', '.language-toggle-btn', function(e) {
    e.preventDefault()

    $.post( '/change-language', { language: $(this).attr('data-language') }).done( (response) => {  
      location.reload()
    }).fail( function(err) {
      // generateErrorSwal(err)
    })
    
  })

  // DataTable Defaults
  if ( typeof $.fn.dataTable !== 'undefined' ) {
    $.extend( true, $.fn.dataTable.defaults, {
      responsive: {
        breakpoints: [
          { name: 'largeDesktop', width: Infinity },
          { name: 'desktop', width: 1600 },
          { name: 'tablet-l', width: 1100 },
          { name: 'tablet-p', width: 768 },
          { name: 'mobile-l', width: 480 },
          { name: 'mobile-p', width: 320 }
        ]
      },
      processing: true,
      dom: `
        <'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 d-flex justify-content-end'f>>
        <'row'<'col-sm-12'tr>>
        <'row'<'col-sm-12 col-md-5 d-flex flex-column justify-content-center'i><'col-sm-12 col-md-7 dataTables_pager'p>>
      `,
      lengthMenu: [5, 10, 25, 50],
      pageLength: 10,
      language: {
        'lengthMenu': 'Display _MENU_',
      },
      drawCallback: function( settings ) {
        initTooltip()
      }
    } )
  }

})

window.dateTimeFormat = {
  full: 'DD MMMM YYYY @ HH:mm',
  fullShort: 'DD MMM YYYY @ HH:mm',
  date: 'DD MMMM YYYY',
  dateShort: 'DD MMM YYYY',
  time: 'HH:mm',
  unix: 'X'
}

/**
 * Returns a string with the current page URL appended with the parameter
 * @param {*} dest the destination to append to current page URL
 */
window.appendToUrl = (dest) => {
  return window.location.pathname.replace(/\/+$/, '') + dest
}

window.initTooltip = () => {
  $('[data-toggle="tooltip"]').tooltip()
}

window.initSelectPicker = () => {
  $('.selectpicker').selectpicker()
}

window.refreshSelectPicker = () => {
  $('.selectpicker').selectpicker('refresh')
}

window.toggleFormBtnLoading = ( form, isLoading ) => {

  if ( isLoading ) {
    form.find('.btn').prop('disabled', true )
    form.find('.btn[type=submit]').addClass('spinner spinner-white spinner-right')
  } else {
    form.find('.btn').prop('disabled', false )
    form.find('.btn[type=submit]').removeClass('spinner spinner-white spinner-right')
  }

}

window.swalDeletePrompt = (title, url) => {
  return new Promise( function ( resolve, reject ) {

    Swal.fire({
      title: 'Are you sure?',
      text: `This will delete this ${ title } completely.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return $.ajax({
          url: url,
          type: 'DELETE'
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Success!', 
          'Successfully deleted',
          'success'
        ).then( () => {
          resolve()
        })
      }
    }).catch( err => {
      generateErrorSwal(err)
    })
  })
}

window.generateErrorSwal = (err) => {
  var swalTitle = err.responseJSON.type || 'Error'
  var errMsg = 'There was a problem.'
  var swalType = 'error'
      
  if (err.status === 409) {
    errMsg = err.responseJSON.message
    if (err.responseJSON.type === 'Info') {
      swalType = 'info'
    }
  }
  
  Swal.fire(
    swalTitle, 
    errMsg,
    swalType
  )
}

/**
* Initialize custom behavior for bootstrap tabs
* to save state on page load
*/
window.initTabState = () => {

  if (location.hash) {
    $('a[href="' + location.hash + '"]').tab('show')
  }

  // var activeTab = localStorage.getItem('activeTab');
  // if (activeTab) {
  //   $('a[href="' + activeTab + '"]').tab('show');
  // }
  
  $('body').on('click', 'a[data-toggle="tab"]', function (e) {
    e.preventDefault()
    var tab_name = this.getAttribute('href')
    if (history.pushState) {
      history.pushState(null, null, tab_name)
    } else {
      location.hash = tab_name
    }
    localStorage.setItem('activeTab', tab_name)
  
    $(this).tab('show')
    return false
  })

  $(window).on('popstate', function () {
    var anchor = location.hash || $('a[data-toggle=\'tab\']').first().attr('href')
    $('a[href="' + anchor + '"]').tab('show')
  })

}

window.initPhoneCodeSelect = async ( elem, parentElem = null ) => {

  console.log(typeof elem)
  const phoneCodes = await $.get( '/api/phone-codes' )

  const options = {
    data: phoneCodes,
    width: '100%',
    dropdownAutoWidth: true,
    templateResult: (data) => {
      return $(data.html)
    },
    templateSelection: function(data) {
      return $(data.text)
    }
  }

  if ( parentElem ) {
    options.dropdownParent = parentElem
  }

  elem.select2(options)

}

window.initCountrySelect = async ( elem, parentElem = null ) => {

  const countryOptions = await $.get( '/api/countries' )

  const options = {
    data: countryOptions,
    width: '100%',
    dropdownAutoWidth: true,
    templateResult: (data) => {
      return $(data.html)
    },
    templateSelection: function(data) {
      return $(data.text)
    }
  }

  if ( parentElem ) {
    options.dropdownParent = parentElem
  }

  elem.select2(options)

}