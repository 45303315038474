// Keenthemes' plugins
window.KTUtil = require('@/js/components/util.js');
window.KTApp = require('@/js/components/app.js');
window.KTCard = require('@/js/components/card.js');
window.KTCookie = require('@/js/components/cookie.js');
window.KTDialog = require('@/js/components/dialog.js');
window.KTHeader = require('@/js/components/header.js');
window.KTImageInput = require('@/js/components/image-input.js');
window.KTMenu = require('@/js/components/menu.js');
window.KTOffcanvas = require('@/js/components/offcanvas.js');
window.KTScrolltop = require('@/js/components/scrolltop.js');
window.KTToggle = require('@/js/components/toggle.js');
// window.KTWizard = require('@/js/components/wizard.js');
require('@/js/components/datatable/core.datatable.js');
// require('@/js/components/datatable/datatable.checkbox.js');
// require('@/js/components/datatable/datatable.rtl.js');

// Metronic layout base js
window.KTLayoutAside = require('@/js/layout/base/aside.js');
window.KTLayoutAsideMenu = require('@/js/layout/base/aside-menu.js');
window.KTLayoutAsideToggle = require('@/js/layout/base/aside-toggle.js');
window.KTLayoutBrand = require('@/js/layout/base/brand.js');
window.KTLayoutContent = require('@/js/layout/base/content.js');
window.KTLayoutFooter = require('@/js/layout/base/footer.js');
window.KTLayoutHeader = require('@/js/layout/base/header.js');
window.KTLayoutHeaderMenu = require('@/js/layout/base/header-menu.js');
window.KTLayoutHeaderTopbar = require('@/js/layout/base/header-topbar.js');
window.KTLayoutStickyCard = require('@/js/layout/base/sticky-card.js');
window.KTLayoutStretchedCard = require('@/js/layout/base/stretched-card.js');
window.KTLayoutSubheader = require('@/js/layout/base/subheader.js');

// Metronic layout extended js
// window.KTLayoutChat = require('@/js/layout/extended/chat.js');
// window.KTLayoutDemoPanel = require('@/js/layout/extended/demo-panel.js');
// window.KTLayoutExamples = require('@/js/layout/extended/examples.js');
// window.KTLayoutQuickActions = require('@/js/layout/extended/quick-actions.js');
// window.KTLayoutQuickCartPanel = require('@/js/layout/extended/quick-cart.js');
// window.KTLayoutQuickNotifications = require('@/js/layout/extended/quick-notifications.js');
// window.KTLayoutQuickPanel = require('@/js/layout/extended/quick-panel.js');
// window.KTLayoutQuickSearch = require('@/js/layout/extended/quick-search.js');
window.KTLayoutQuickUser = require('@/js/layout/extended/quick-user.js');
window.KTLayoutScrolltop = require('@/js/layout/extended/scrolltop.js');
// window.KTLayoutSearch = window.KTLayoutSearchOffcanvas = require('@/js/layout/extended/search.js');

require('@/js/layout/initialize.js');
require('@/js/custom.js');